import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Helmet } from "react-helmet"
import { Layout, Article } from "../components"
import { useAppSelector } from "../app/hooks"

interface Frontmatter {
  slug: string
  title: string
  language: string
}

interface ArticleQuery {
  allMarkdownRemark: {
    edges: {
      node: {
        frontmatter: Frontmatter
      }
    }[]
  }
}

const FaqPage: React.FC = () => {
  const language = useAppSelector((state: any) => state.language.config)
  const languageValue = useAppSelector((state) => state.language.value)

  const query: ArticleQuery = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        sort: { order: DESC, fields: [frontmatter___title] }
        limit: 1000
      ) {
        edges {
          node {
            frontmatter {
              slug
              title
              language
            }
          }
        }
      }
    }
  `)

  const formatQuery = (query: ArticleQuery) => {
    return query.allMarkdownRemark.edges.map((edge) => {
      return edge.node.frontmatter
    })
  }

  const articles: Frontmatter[] = formatQuery(query)
  return (
    <>
      <Layout>
        <Helmet>
          <title>FAQ | Papelzinho ™</title>
        </Helmet>

        <section className="px-5 pt-5 pb-0 text-center">
          <h1 className="mb-0">{language["FAQ"].title}</h1>
        </section>

        <hr className="my-5" />

        <section className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 px-5">
          {articles.map((article) => {
            if (article.language !== languageValue) {
              return
            }
            return <Article key={article.slug} article={article} />
          })}
        </section>
      </Layout>
    </>
  )
}

export default FaqPage
